
import React from "react"
import { graphql, Link } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Seminars = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
      <Bio />
      <p>
        Rabbi Fine offers 3 types of seminars: one's for <Link to={'/seminars/parents'}>parents</Link>, one's for <Link to={'/seminars/teens'}>teens</Link>, and one's for <Link to={'/seminars/therapistsAndTeachers'}>therapists and teachers</Link>. 

        <h3>A word on Yehudah Fine's Seminars (from others):</h3>
        <em>"Reclaiming Our Teenagers and Ourselves"</em><br/>
        Teen violence, suicide and drugs . . . Scary times. Our children need us. Learn how to reach out to them effectively to build trust and love.<br/><br/>
        <blockquote>"Rabbi Fine's work is for everyone struggling to be renewed by timeless simple truths in the midst of all our modern desperation. He reflects and teaches the simple and sustaining sweetness of the human capacity for love, hope, and resilience." <br/><br/><strong>- Kirk Barton, M.D., Department of Psychiatry, Harvard Medical School</strong></blockquote>

        Yehuda Fine’s electrifying seminars have reached over 24,000 teens across North America. At each seminar teens reveal to him their deepest held secrets and concerns anonymously. These are issues they would almost never discuss with their best friend or their parent. <br/><br/>

        
        <blockquote>"Rabbi Fine's work is an invitation to us all. The rabbi is modeling a path for healing the wounds of denial and unexpressed love we carry. The Rabbi teaches us that when we truly become engaged in life, our appreciation for being alive grows. He shows us a road that passes through others' lives and leads us back to ourselves. But we arrive home enriched, in touch, and with a larger heart as a result of the journey."" <br/><br/><strong>- Earl Hipp, Author of <strong>Help for the Hard Times: Getting Through Loss</strong> and other books for adolescents. </strong></blockquote>

        The message of Rabbi Yehudah Fine's seminars is simple but not easy—teens want to be close to their parents and teachers. We need courage, honesty and some basic tools to reach out to them. The stakes are high—the rewards, priceless! Yet so many parents have lost confidence in their ability to parent and to transmit values. Sometimes the walls seem so high. Rabbi Fine shows parents and teachers how, in the midst of fear and discomfort, they can take steps to restore and enrich the family. Through it all, his message contains reminders of the higher spiritual purpose behind every word we speak and every move we make. Yehudah Fine, noted lecturer and family therapist, is the author of Times Square Rabbi — Finding the Hope in Lost Kids' Lives (Hazelden), and a member of the guidance staff at Yeshiva University. His newspaper columns on teen issues have appeared in newspapers throughout the country.<br/><br/>

        <blockquote>"We've made Yehudah's work a part of our family education program. Our parents and teens were so moved by his compassion. It's very clear that he has been listening carefully to the thousands of young people with whom he has worked." <br/><br/><strong>- Rabbi Howard Addison, Temple Sinai, Philadelphia, PA</strong></blockquote>
<br/><br/>
        Here is a sample of material present in his seminars for <Link to={"/seminars/parents"}>parents</Link>: 
        </p>
      <h3>How to get Close to your Kids</h3>
    <h4>10 Suggestions</h4>
<p>
<ol>
<li>Tell your children you love them and why.</li>
<li>Stay in touch with your kid's world—their music and their friends. Make your home a place for their friends.</li>
<li>Stay in close communication with your child. Don't use poverty or overwork as excuses.</li>
<li>Be flexible. What worked when your child was 9 is irrelevant at 16. And what worked for your parents when raising you might not work for your children today.</li>
<li>Don't let mistakes as a parent make you stop trying. Children learn mightily from how you deal with life.</li>
<li>Effective parenting depends strongly on having a working relationship with your spouse or ex-spouse. By working together, you help build self-confidence and strength in your child.</li>
<li>As a parent you don't have to be perfect. It's okay to be inconsistent at times. Let your kid know about how you struggle with important issues.</li>
<li>Be prepared to get competent professional help if your child is in serious trouble.</li>
<li>Put prime time into your family life. Invest in family at least what you invest in your career.</li>
<li>Admit your feelings of discomfort in discussing the "hot topics" (sex., drugs, etc.) Sharing your vulnerabilities builds your teen's character.</li>
</ol>
  <br/>
</p>
    </Layout>
  )
}

export default Seminars

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`